import Clock from 'Clock';

import Rx from 'rx-lite-dom';
import DomStreamFactory from 'DomStreamFactory';
import EngagementProcessor from 'EngagementProcessor';
import EngagementTracker from 'EngagementTracker';

const dom = Rx.DOM;

class EngagementListener {
    constructor(publishChannel, tracker) {
        this.publishChannel = publishChannel;
        this.tracker = tracker;
        this.sendInterval = 5000;
        this.lastSent = new Date().getTime();
        this.state = null;
        this.subscription = null;
        this.unload = "unload_key";
    }

    getResetEvent(state) {
        return {
            time: new Date(state.time),
            name: "reset",
            rawEvent: {
                clicks: state.clicks.length,
                focus: state.focus.length,
                scrolls: state.scrolls.length,
                dimensions: state.dimensions.length,
                moves: state.moves.length,
                activeChangedEvents: state.activeChangedEvents.length
            }
        };
    }

    sendIfNecessary(state) {
        this.state = state;
        const now = new Date().getTime();

        if(now - this.lastSent > this.sendInterval) {
            this.lastSent = now;
            this.sendImmediately();
        }
    }

    handleNext(message) {
        if (message && message.maxEngagementReached) {
            this.subscription.onCompleted()
        } if (message && message.maxTimeOnPageReached && message.engaged == 0) {
            this.subscription.onCompleted()
        } else if (message === this.unload) {
            this.sendImmediately();
        } else {
            this.sendIfNecessary(message);
        }
    }

    sendImmediately() {
        if(this.state.isEmpty) return;
        if(!this.tracker.track(this.state)) {
            console.log("disposing of subscription.");
            this.subscription.dispose();
        }
        else {
            this.publishChannel.onNext(this.getResetEvent(this.state));
        }
    }

    subscribe(activityStream) {
        let unloadStream = dom.unload(window).map(x => this.unload);
        this.subscription =
            activityStream
                .merge(unloadStream)
                .subscribe(this.handleNext.bind(this));
        return this.subscription;
    }
}

export const main = () => {
    // wiring stuff
    try {
        if(window.storygizeEngagementIntialized === true) {
            return;
        }
        else {
            window.storygizeEngagementIntialized = true;
        }
        const clock = new Clock();
        const tracker = new EngagementTracker();
        const engagementProcessor = new EngagementProcessor(clock);

        tracker.track(engagementProcessor.getSeed());

        const browserStream = new DomStreamFactory(clock).create();
        const messageStream = new Rx.Subject();

        tracker.fireGeminiCookieSync();
        tracker.addCookieSyncIframe();

        const eventStream = messageStream.merge(browserStream);
        const outputStream = engagementProcessor.process(eventStream);

        new EngagementListener(messageStream, tracker).subscribe(outputStream);
    } catch(e) {}
};

export const pageViewAndCookieSync = () => {
    try {
        const clock = new Clock();
        const tracker = new EngagementTracker();
        const engagementProcessor = new EngagementProcessor(clock);

        tracker.track(engagementProcessor.getSeed());
    } catch(e) {}
};