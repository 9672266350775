import Rx from 'rx-lite-dom'
import VideoStreamFactory from 'VideoStreamFactory';

const dom = Rx.DOM;
const rx = Rx.Observable;

Rx.config.useNativeEvents = true;

function Event(name, event, time) {
    return {
        rawEvent: event, // cant be immutable or we may lose native browser events that cannot be copied
        name: name,
        time: time
    };
}

export default class DomStreamFactory {

    constructor(clock, win, node, brightcoveApi) {
        this.clock = clock;
        this.window = win || window;
        this.node = node || window.document;
        this.brightcoveOverride = brightcoveApi;
    }

    create(settings = {}) {

        let clock = this.clock;

        function track(name, stream) {
            return stream.map(event => {
                return Event(name, event, clock.now());
            });
        }

        let window = this.window;
        let node = this.node;

        const focus = dom.fromEvent(window, "focus");
        const blur = dom.fromEvent(window, "blur");
        const click = dom.click(node);
        const scroll = dom.scroll(node);
        const move = dom.mousemove(node);


        let touchstart = rx.empty();
        let touchmove = rx.empty();
        if (dom.touchstart)
            touchstart = dom.touchstart(node);
        if (dom.touchmove)
            touchmove = dom.touchmove(node);

        const resize = dom.resize(window);

        const longBounce = 500;
        const shortBounce = 100;

        const videoStreamFactory = new VideoStreamFactory(this.window, this.node, this.brightcoveOverride).create(settings);

        return rx.merge(
            track("focus", focus),
            track("blur", blur),
            track("click", click),
            track("resize", resize.debounce(longBounce)),
            track("scroll", scroll.debounce(longBounce)),
            track("move", move.sample(shortBounce)),
            track("touch-start", touchstart),
            track("touch-move", touchmove.sample(shortBounce)),
            track("video-play", videoStreamFactory.sample(shortBounce)),
            track("timer", rx.timer(5000, 5000))
        );
    }
}
