import Rx from 'rx-lite-dom'

const dom = Rx.DOM;
const rx = Rx.Observable;

Rx.config.useNativeEvents = true;

class BrightcoveAssistant {
    constructor(win, node) {
        this.window = win || window;
        this.node = node || document;
    }

    isAvailable() {
        this.brightcove = this.brightcove || this.window.brightcove || this.window.brightcoveJS;
        return !!this.brightcove;
    }

    getExperienceMetadata() {
        return Array.prototype.map.call(this.node.getElementsByClassName("BrightcoveExperience"), (el) => {
            let player = this.brightcove.getPlayer(el.id);
            return {
                id: player && player.callback,
                player: player
            };
        }).filter((e) => !!e.id);
    }

    getProgressStream(metadata) {
        let messageSubject = new Rx.Subject();

        let videoPlayer = new this.brightcove.modules.videoPlayer(metadata.player);
        videoPlayer.addEventListener("mediaProgress", function (e) {
            messageSubject.onNext("media-progress");
        });

        return messageSubject.map(x => x);
    }

}

class BrightcoveVideoManager {
    constructor(win, node, brightcove) {
        this.window = win;
        this.node = node;
        this.brightcove = brightcove;
        this.managedExperiences = {};
    }

    alreadyListening(metadata) {
        return this.managedExperiences.hasOwnProperty(metadata.id);
    }
    
    getMergedNewStreams() {
        let streams = this.brightcove.getExperienceMetadata().map((metadata) => {
            if (this.alreadyListening(metadata))
                return rx.empty();
            else {
                this.managedExperiences[metadata.id] = metadata;
                return this.brightcove.getProgressStream(metadata);
            }
        });
        return rx.merge.apply(rx, streams);
    }

    getBrightcoveStream() {
        if (this.brightcove.isAvailable()) {
            return this.getMergedNewStreams();
        } else {
            return rx.empty();
        }
    }
}

export default class VideoStreamFactory {

    constructor(win, node, brightcove) {
        this.window = win || window;
        this.node = node || window.document;
        this.brightcove = brightcove || new BrightcoveAssistant(this.window, this.node);
    }

    _getOrCreateVideoImplementation() {
        this.brightcoveManager = this.brightcoveManager || new BrightcoveVideoManager(this.window, this.node, this.brightcove);
        return this.brightcoveManager.getBrightcoveStream();
    }

    create(settings = {}) {
        let interval = settings.videoInterval || 2500;
        return rx.interval(interval).flatMap(this._getOrCreateVideoImplementation.bind(this));
    }
}
