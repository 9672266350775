import LZString from "lz-string";

export default class EngagementTracker {
    constructor() {
        var scripts = Array.prototype.slice.call(document.getElementsByTagName("script"));
        let ourScript = null;
        for (let i = 0; i < scripts.length; i++) {
            let script = scripts[i].src;
            if (script && script.indexOf("storygize") != -1 && (script.indexOf("abdo.js")!=-1 || script.indexOf("abdo.dev.js")!=-1) ) {
                ourScript = script;
                break;
            }
        }
        if (ourScript) {
            const tokens = ourScript.split("/");
            this.domain = tokens[tokens.length-4];
            this.uuid = tokens[tokens.length-2];
            this.functional = true;
        } else {
            console.log("Unable to find our script on the page so can not figure out where to send data.");
            this.domain = "//example.com";
            this.uuid = "storygize-integration-failure";
            this.functional = false;
        }
        this.img = new Image();
        this.img.onerror = () => {
            this.functional = false;
        };

    }

    addCookieSyncIframe() {
        const addIframe = () => {
            let cookieSyncFrame = document.createElement("iframe");
            cookieSyncFrame.src = `//${this.domain}/${this.uuid}/cs.html`;
            cookieSyncFrame.style.width = 0;
            cookieSyncFrame.style.height = 0;
            cookieSyncFrame.style.border = "0 none";
            cookieSyncFrame.style.position = "absolute";
            document.body.appendChild(cookieSyncFrame);
        };
        if(document.readyState === "complete")
            addIframe();
        else
            window.addEventListener("load", addIframe);
    }

    fireGeminiCookieSync() {
        const addGeminiSync = () => {
            new Image().src = `//${this.domain}/scm/c1a8b232-5312-44ad-8790-bb2e121ad447/${this.uuid}`;
        };
        if(document.readyState === "complete")
            addGeminiSync();
        else
            window.addEventListener("load",addGeminiSync);
    }

    serialize(data) {
        return LZString.compressToEncodedURIComponent(JSON.stringify(data));
    }

    deserialize(data) {
        return JSON.parse(LZString.decompressFromEncodedURIComponent(data));
    }

    getURL(data) {
        return `//${this.domain}/e/${this.uuid}/${this.serialize(data)}`;
    }

    track(data) {
        if (this.functional && data) {
            // this.img.src = this.getURL(data);
            // this.focusImage();
            this.img.src = this.getURL(data);
        }
        return this.functional;
    }
}
