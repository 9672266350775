import Immutable from 'seamless-immutable'
import {MAX_ENGAGEMENT} from 'EngagementTimeout'
import Rx from 'rx-lite-dom'
const rx = Rx.Observable;

function dateDiff(from, to) {
    return to.getTime() - from.getTime();
}

export function toClickInfo(event) {
    let target = event.rawEvent.target || {};
    let interestingNode = findInterestingNode(target);

    return toCoordinate(event.time, event.rawEvent).merge(
        {
            h: interestingNode.href,
            img: firstImageSrcIn(interestingNode),
            selector: extractSelector(interestingNode) || undefined
        }
    );
}

function getSelectorForElement(el) {
    let id = el.id;
    let classes = el.className;
    let str = (el.tagName) ? el.tagName.toLowerCase() : "";
    if (id) str += "#" + id;
    if (classes) str += "." + classes.replace(/\s+/g, ".");
    return str;
}

function extractSelector(el) {
    let parentNode = el.parentNode;
    let str = "";
    if (parentNode) {
        str += getSelectorForElement(parentNode) + ">";
    }
    return str + getSelectorForElement(el);
}

function findInterestingNode(el) {
    return findAnchor(el) || el;
}

function findAnchor(el) {
    if (!el) return el;
    var lcTagName = el.tagName && el.tagName.toLowerCase();

    if (lcTagName == "a") {
        return el;
    } else {
        return findAnchor(el.parentNode);
    }
}
function firstImageSrcIn(el) {
    let images = el.getElementsByTagName && el.getElementsByTagName("img");

    let first;
    if (images && images.length)
        first = images[0].src;

    return first;
}

function toFocusChangeEvent(event, newActiveState) {
    return Immutable({
        t: event.time.getTime(),
        active: newActiveState,
        eventTrigger: event.name
    });
}

function toCoordinate(time, mouse) {
    return Immutable({
        t: time.getTime(),
        cX: mouse.clientX,
        cY: mouse.clientY,
        pX: mouse.pageX,
        pY: mouse.pageY
    });
}

function pipeline() {
    let fns = Array.prototype.slice.call(arguments);
    return function (state, event) {
        let result = state;
        for (var i = 0; i < fns.length; i++) {
            result = fns[i](result, event, state);
        }
        return result;
    };
}

let __singletonId = undefined;
export class SessionIdHolder {
    static getId() {
        __singletonId = __singletonId || 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        return __singletonId;
    }

    static clearIdForTest() {
        __singletonId = undefined;
    }
}

function merge(state, changes) {
    changes.isEmpty = false;
    return state.merge(changes);
}

export default class EngagementProcessor {
    constructor(clock, win, id) {
        this.timeoutSequence = [5000,10000,15000,30000,60000];
        this.clock = clock;
        this.window = win || window;
        this.id = id || SessionIdHolder.getId();
        let now = this.clock.now();
        this.seed = Immutable({
            active: false,
            id: this.id,
            time: now,
            begin: now.getTime(),
            engaged: 0,
            focus: [],
            activeChangedEvents: [],
            clicks: [],
            moves: [],
            scrolls: [],
            dimensions: [this.getBrowserDimensions(now)],
            isEmpty: true,
            timeOnPage: 0
        });
    }

    captureMovement(state, event) {
        let changes = {};
        if (event.name == "move") {
            changes.moves = state.moves.concat([toCoordinate(event.time, event.rawEvent)]);
            return merge(state, changes);
        } else if (event.name == "touch-start" || event.name == "touch-move") {
            changes.moves = state.moves.concat([toCoordinate(event.time, event.rawEvent.touches[0])]);
            return merge(state, changes);
        }
        return state;
    }

    captureScroll(state, event) {
        let changes = {};
        let window = this.window;
        let document = window.document;
        if (event.name == "scroll") {
            let scroll = {
                t: event.time.getTime(),
                sT: (document.documentElement.scrollTop || document.body.parentNode.scrollTop || document.body.scrollTop),
                sL: (document.documentElement.scrollLeft || document.body.parentNode.scrollLeft || document.body.scrollLeft)
            };
            changes.scrolls = state.scrolls.concat([scroll]);
            return merge(state, changes);
        }
        return state;
    }


    getBrowserDimensions(time) {
        let document = this.window.document;
        return {
            t: time.getTime(),
            cW: document.documentElement.clientWidth,
            cH: document.documentElement.clientHeight,
            pW: document.body.scrollWidth,
            pH: document.body.scrollHeight
        };
    }

    captureClicks(state, event) {
        let changes = {};
        if (event.name == "click") {
            changes.clicks = state.clicks.concat([toClickInfo(event)]);
            return merge(state, changes);
        }
        return state;
    }

    detectSleep(state, event) {
        // console.log("prev time", new Date(state.time));
        // console.log("event time", new Date(event.time));
        // console.log(dateDiff(state.time, event.time))
        if (dateDiff(state.time, event.time) > this.timeoutSequence[0] ) {
            const now = this.clock.now();
            // console.log("detected sleep, changing time to " + now);
            const changes = {
                time: now
            };
            changes.timeOnPage = state.timeOnPage + dateDiff(state.time, event.time);
            // console.log("timeOnPage changed to: " + changes.timeOnPage);
            if(this.timeoutSequence.length > 1) {
                this.timeoutSequence = this.timeoutSequence.slice(1);
            }
            return merge(state, changes);
        } else {
            return state;
        }
    }

    calculateEngagement(state, event) {
        let changes = {};
        if (event.name != "reset" && event.name != "timer") {
            changes.active = event.name != "blur";
            changes.time = event.time;
            changes.timeOnPage = state.timeOnPage + dateDiff(state.time, event.time);
            // console.log("timeOnPage changed after event "+event.name+" to: " + changes.timeOnPage);
            if(state.active) {
                // console.log("prev time", new Date(state.time));
                // console.log("event time", new Date(event.time));
                changes.engaged = state.engaged + dateDiff(state.time, event.time);
                // console.log("engaged changed to for event " + event.name + " engaged: " + changes.engaged);
            }
            return merge(state, changes);
        }
        return state;
    }

    captureDimensions(state, event) {
        let changes = {};
        if (event.name == "resize") {
            changes.dimensions = state.dimensions.concat([this.getBrowserDimensions(event.time)]);
            return merge(state, changes);
        }
        return state;
    }

    captureFocusChange(state, event, oldState) {
        // focus list is the history of focus changes, from engaged to unengaged
        let changes = {};
        if (state.active && !oldState.active) {
            changes.focus = state.focus.concat([event.time.getTime()]);
            changes.activeChangedEvents = state.activeChangedEvents.concat([toFocusChangeEvent(event, state.active)]);
            return merge(state, changes);
        } else if (!state.active && oldState.active) {
            changes.focus = state.focus.concat([event.time.getTime()]);
            changes.activeChangedEvents = state.activeChangedEvents.concat([toFocusChangeEvent(event, state.active)]);
            return merge(state, changes);
        }
        return state;
    }

    handleReset(state, event) {
        let changes = {};
        if (event.name == "reset") {
            const resetEvent = event.rawEvent;
            changes.clicks = state.clicks.slice(resetEvent.clicks);
            changes.moves = state.moves.slice(resetEvent.moves);
            changes.scrolls = state.scrolls.slice(resetEvent.scrolls);
            changes.focus = state.focus.slice(resetEvent.focus);
            changes.dimensions = state.dimensions.slice(resetEvent.dimensions);
            changes.activeChangedEvents = state.activeChangedEvents.slice(resetEvent.activeChangedEvents);
            changes.isEmpty = true;
            return state.merge(changes);
        }
        return state;
    }

    getSeed() {
        return this.display(this.seed);
    }

    maybeOutputData(state) {
        if(state.isEmpty) {
            return rx.empty();
        }
        else
            return rx.just(this.display(state))
    }

    display(state) {
        return state.merge({
            time: state.time.getTime(),
            end: this.clock.now().getTime(),
            maxEngagementReached: state.engaged >= MAX_ENGAGEMENT,
            maxTimeOnPageReached: state.timeOnPage >= MAX_ENGAGEMENT
        });
    }

    process(stream) {
        return stream
            .scan(pipeline(
                this.detectSleep.bind(this),
                this.calculateEngagement.bind(this),
                this.captureClicks.bind(this),
                this.captureDimensions.bind(this),
                this.captureScroll.bind(this),
                this.captureMovement.bind(this),
                this.captureFocusChange.bind(this),
                this.handleReset.bind(this)
            ), this.seed)
            .flatMap(this.maybeOutputData.bind(this));
    }
}


